import trim from 'lodash/trim';

const isAbsoluteUrl = (url: string) =>
  url ? url.indexOf('://') > 0 || url.indexOf('//') === 0 : false;

export const sanitizeLinkUrl = (url: string) => {
  if (isAbsoluteUrl(url)) {
    return url;
  }
  return url === 'home' ? '/' : `/${trim(url, '/')}`.replace('//', '/');
};
