export interface IBrandContent {
  content: {
    content: [
      {
        brand: {
          content: {
            logo: {
              filename: string;
              alt: string;
            };
            name: string;
            description: {
              content: unknown[];
            };
            product_category?: {
              shopify_category?: {
                items?: {
                  name: string;
                }[];
              };
            }[];
          };
        };
      },
    ];
  };
  _meta: {
    slug: string;
    full_slug: string;
  };
}

export const mapBrand = (brand: IBrandContent) => {
  const brandContent = brand?.content?.content?.[0]?.brand?.content;
  const brandMeta = brand?._meta;

  return {
    name: brandContent?.name,
    logo: brandContent?.logo,
    description: brandContent?.description,
    slug: brandMeta?.slug,
  };
};
