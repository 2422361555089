import { render, staticRenderFns } from "./ProductInformation.vue?vue&type=template&id=b11db648&scoped=true&"
import script from "./ProductInformation.vue?vue&type=script&lang=ts&"
export * from "./ProductInformation.vue?vue&type=script&lang=ts&"
import style0 from "./ProductInformation.vue?vue&type=style&index=0&id=b11db648&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b11db648",
  null
  
)

export default component.exports