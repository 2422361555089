import {
  IProductResult,
  productResultGetters,
} from '@unified-commerce/gpc-vue-storefront-search-io';
import { Product, productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';

const kebabCase = (str: string) =>
  str
    .split(' ')
    .map((part) => part.toLowerCase())
    .join('-');

const useBrandLink = (product: Product | IProductResult = {} as any) => {
  const isProduct = productGetters.isProduct(product as Product);

  const vendor = isProduct
    ? productGetters.getProductVendor(product as Product)
    : productResultGetters.getProductVendor(product as IProductResult);

  return vendor ? `/brands/${kebabCase(vendor)}` : '';
};

export default useBrandLink;
