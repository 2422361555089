<template>
  <ProductPage
    :cart-loading="cartLoading"
    :product-recommendation="productRecommendation"
    :options="options"
    :options-not-selected="optionsNotSelected"
    :selected-options="selectedOptions"
    :brand-story="brandStory"
    :asku="asku /* fitment only */"
    :variant-id="variantId"
    @add-to-cart="addingToCart"
    @product-option-selected="productOptionSelected"
    @bundled-options-selected="bundledOptionsSelected"
  />
</template>

<script>
/* eslint-disable max-lines-per-function */
import {
  computed,
  defineComponent,
  onBeforeUnmount,
  provide,
  reactive,
  ref,
  useAsync,
  useContext,
  useMeta,
  useRoute,
  watch,
} from '@nuxtjs/composition-api';
import {
  getFitmentAskus,
  getFitmentByKtypeParams,
  searchGetters,
  useSearch,
} from '@unified-commerce/gpc-vue-storefront-search-io';
import {
  cartGetters,
  productGetters,
  useCart,
  useProduct,
} from '@unified-commerce/gpc-vue-storefront-shopify';
import { getStoryBlokVersion, useContent } from '@unified-commerce/gpc-vue-storefront-storyblok';
import { sharedRef } from '@vue-storefront/core';
import trim from 'lodash/trim';

import ProductPage from '~/components/Pages/ProductPage/ProductPage.vue';
import {
  useMyGarage,
  useRecentlyViewedProducts,
  useShopifyProduct,
  useUiState,
} from '~/composables';
import { useBundledProductOptions } from '~/composables/useBundledProductOptions';
import useUiHelpers from '~/composables/useUiHelpers';
import useUiNotification from '~/composables/useUiNotification';
import { getProductFailedToAddCartNotification } from '~/composables/useUiNotification/commonNotifications';
import analytics from '~/helpers/analytics';
import {
  getOptionsWithUnavailabilityStatus,
  handleUnavailableOptionSelection,
  setDefaultSelectedOptions,
  toVariantSummary,
} from '~/helpers/bundleVariants/multi-variant-utils';
import productMeta from '~/helpers/meta/product';
import getFitmentByAskusParams from '~/helpers/search/getFitmentByAskusParams';
import getFitmentWithAskuParams from '~/helpers/search/getFitmentWithAskuParams';
import { sanitiseProductId } from '~/helpers/shopify';

export const NOTIFICATION_KEY_PRODUCT_ADDED = 'product_added';

const ADDITIONAL_FIELDS = [
  { name: 'material', field: 'material' },
  { name: 'materialThickness', field: 'material_thickness' },
];

export default defineComponent({
  name: 'ProductPageRoute',
  components: {
    ProductPage,
  },
  transition: 'fade',
  // eslint-disable-next-line max-lines-per-function
  setup(_props, context) {
    const { $config } = useContext();
    const { slug } = context.root.$route.params;
    const route = useRoute();
    const { userSelectedVehicle, setVehicleAskus, vehicleAskus, setRecentlyAddedProduct } =
      useUiState();
    const { send: sendNotification } = useUiNotification();
    const { setCurrentRouteAsNotFound, setCurrentRouteAsInternalError } = useUiHelpers();
    const options = ref([]);
    const optionsNotSelected = ref([]);
    const contentLoading = sharedRef(true, `content-loading-${slug}`);
    const selectedBundleVariants = ref(null);
    const hasBundledProductValidationError = ref(false);
    const selectedOptions = reactive([]);

    const { bundledProductOptions, getBundledProductOptions } = useBundledProductOptions(slug);

    const { addItem, loading: cartLoading, error: cartError } = useCart();

    const productPath = computed(() => trim(route.value.path, '/'));

    const { search: searchProductRecommendation, content = {} } = useContent(productPath.value);

    const { search: searchBrand, content: brandContent = {} } = useContent(`brandSearch-${slug}`);

    const brandStory = computed(() => {
      if (brandContent.value && brandContent.value[0]) {
        return brandContent.value[0];
      }

      return null;
    });

    /* WIP – Keeping both methods of fetching product until all users of original product
     *  have been dealt with
     */
    const { products } = useProduct(slug);
    const product = computed(() => productGetters.getFiltered(products.value)[0]);

    const { product: shProduct, error, fetchProduct } = useShopifyProduct();
    const {
      id,
      price,
      compareToPrice,
      hasOptions,
      productGallery,
      stock,
      sku,
      vendor,
      partNumber,
      asku,
      breadcrumbs,
      variantBySelectedOptions,
      hasVariantSelected,
    } = shProduct;

    const { pushRecentlyViewedProduct } = useRecentlyViewedProducts();
    onBeforeUnmount(() => {
      pushRecentlyViewedProduct(product.value);
    });

    const variantId = computed(() => {
      if (product.value) {
        if (hasVariantSelected.value) {
          return sanitiseProductId(variantBySelectedOptions?.value?.id);
        }

        return sanitiseProductId(product.value?.variants?.[0]?.id);
      }

      return '';
    });

    const selectedVariantId = computed(() =>
      hasOptions.value
        ? selectedOptions.length !== options.value.length
          ? null
          : product.value?.variantBySelectedOptions?.id
        : product.value?.variantId,
    );

    const productRecommendation = computed(() => {
      if (content.value['0']) {
        return content.value['0'].content;
      }

      return null;
    });

    const { search: searchFitment, result: fitmentResult } = useSearch(
      `${productPath.value}-get-fitment-data`,
    );

    const { search: searchUniversal, result: fitmentUniversalResult } = useSearch(
      `${productPath.value}-get-universal-fitment-data`,
    );

    const getFitmentData = async () => {
      const selectedVehicleCookie = await useMyGarage().getVehicleSelectionFromCookie();
      if (selectedVehicleCookie) {
        await searchFitment(getFitmentByAskusParams([asku.value], selectedVehicleCookie.KtypNr));
      }
    };

    provide('contentLoading', contentLoading);

    provide(
      'isUniversalProduct',
      computed(() => searchGetters.hasNoItems(fitmentUniversalResult.value)),
    );

    provide(
      'fitmentSearchData',
      computed(() => searchGetters.getItems(fitmentResult.value)),
    );
    // end fitment data injection

    provide(
      'userSelectedVehicle',
      computed(() => userSelectedVehicle.value),
    );
    provide(
      'productFitsVehicle',
      computed(() => {
        return vehicleAskus.value?.askus?.includes(asku.value) ? true : false;
      }),
    );

    provide('bundledProductOptions', bundledProductOptions);

    provide('selectedBundleVariants', selectedBundleVariants);

    provide('hasBundledProductValidationError', hasBundledProductValidationError);

    provide('variantId', selectedVariantId);

    useAsync(async () => {
      const version = getStoryBlokVersion($config.ENV, route.value);
      contentLoading.value = true;
      await fetchProduct(slug, {
        additionalFields: ADDITIONAL_FIELDS,
      });

      if (asku.value) {
        await searchUniversal(getFitmentWithAskuParams(asku.value));
      }

      const vehicleSelectedCookie = await useMyGarage().getVehicleSelectionFromCookie();

      if (!product.value) {
        setCurrentRouteAsNotFound({
          errorMessage: 'This product could not be found',
          data: [product, vehicleAskus, userSelectedVehicle, vehicleSelectedCookie],
        });
      } else if (error.value) {
        setCurrentRouteAsInternalError({
          data: {
            error: error.value,
            data: product.value,
          },
        });
      } else {
        try {
          await getBundledProductOptions(product.value);
        } catch (error) {
          console.error('Failed to fetch bundled products', error);
        }

        await Promise.allSettled([
          searchProductRecommendation({
            version,
            relations: 'ProductRecommendation.expert,ProductRecommendation.youtube_video',
            starts_with: 'product-recommendations/',
            custom: {
              'filter_query[product.items.0.id][in]': id.value,
            },
            optimisticStoryLoading: true,
          }),
          searchBrand({
            version,
            relations: 'BrandHero.brand',
            custom: {
              'filter_query[name][in]': `${vendor.value}`,
              'filter_query[component][in]': 'Brand',
            },
            optimisticStoryLoading: true,
          }),
        ]).then(() => (contentLoading.value = false));
      }
      contentLoading.value = false;
    }, route.value.path);

    const addingToCart = async (quantity) => {
      if (
        bundledProductOptions.value?.length &&
        (!selectedBundleVariants.value ||
          selectedBundleVariants.value.length < bundledProductOptions.value.length)
      ) {
        hasBundledProductValidationError.value = true;
        return;
      }

      hasBundledProductValidationError.value = false;

      if (hasOptions.value && selectedOptions.length !== options.value.length) {
        const selectedOptionsList = selectedOptions.map((selectedOption) => selectedOption.name);

        options.value.forEach((option) => {
          if (
            !selectedOptionsList.includes(option.name) &&
            !optionsNotSelected.value.includes(option.name)
          ) {
            optionsNotSelected.value.push(option.name);
          }
        });
        return;
      }

      const customQuery = selectedBundleVariants.value
        ? { key: '__bundleSelectedVariants', value: JSON.stringify(selectedBundleVariants.value) }
        : undefined;

      await addItem({
        product: product.value,
        quantity,
        customQuery,
      });

      analytics({
        event: 'add_to_cart',
        id: product?.value?.id,
        name: product?.value?.name,
        price: price?.value,
        vendor: product?.value?.vendor,
        category: breadcrumbs?.value?.[0]?.text,
      });

      if (cartError.value.addItem) {
        sendNotification(
          getProductFailedToAddCartNotification({
            name: product.value.name,
          }),
        );
      } else {
        setRecentlyAddedProduct({
          variantId: product.value.id,
          name: product.value.name,
          brand: product.value.vendor,
          price: product.value.price.original || product.value.price.current,
          specialPrice: product.value.price.original ? product.value.price.current : null,
          partNumber: partNumber.value,
          sku: sku.value,
          img: productGallery.value[0]?.mobile?.url || '',
        });
      }
    };

    const productOptionSelected = async (selectedOption) => {
      optionsNotSelected.value = [];

      const variants = toVariantSummary(product.value.variants);
      const updatedSelectedOptions = handleUnavailableOptionSelection({
        options: options.value,
        variants,
        selectedOptions,
        selectedOption,
      });

      // Empty array and reset with new values
      selectedOptions.length = 0;
      Object.assign(selectedOptions, updatedSelectedOptions);

      options.value = getOptionsWithUnavailabilityStatus(
        options.value,
        variants,
        updatedSelectedOptions,
      );

      if (options.value.length === selectedOptions.length) {
        await fetchProduct(slug, { selectedOptions, additionalFields: ADDITIONAL_FIELDS });
      }
    };

    const bundledOptionsSelected = ({ unselectedBundleOptions, selectedOptionMetadata }) => {
      if (unselectedBundleOptions.length === 0) {
        hasBundledProductValidationError.value = false;
      }
      selectedBundleVariants.value = selectedOptionMetadata;
    };

    watch(userSelectedVehicle, async (userSelectedVehicle) => {
      if (userSelectedVehicle) {
        await searchFitment(getFitmentByKtypeParams(userSelectedVehicle.KtypNr));
        const askus = getFitmentAskus(fitmentResult.value);

        if (askus) {
          setVehicleAskus(askus, userSelectedVehicle.KtypNr);
          searchFitment(getFitmentByAskusParams([asku.value], userSelectedVehicle.KtypNr));
        }
      }
    });

    // not using watch causes fitment data not to be injected reactively
    watch(asku, getFitmentData, { immediate: true });

    watch(product, () => {
      if (product && product.value) {
        analytics({
          event: 'view_item',
          product: product.value,
          discount: compareToPrice?.value,
          price: price?.value,
        });
      }
    });

    watch(
      [product, selectedOptions],
      () => {
        if (!product.value) {
          return;
        }
        const selectedOptionsWithDefault =
          selectedOptions.length === 0
            ? setDefaultSelectedOptions(productGetters.getOptions(product.value, true))
            : selectedOptions;

        if (selectedOptions.length === 0) {
          selectedOptions.push(...selectedOptionsWithDefault);
        }

        options.value = getOptionsWithUnavailabilityStatus(
          productGetters.getOptions(product.value, true),
          toVariantSummary(product.value.variants),
          selectedOptionsWithDefault,
        );
      },
      { immediate: true },
    );

    useMeta(() => productMeta($config.BASE_URL, route.value.path, shProduct));

    return {
      product,
      sendNotification,
      stock,
      addItem,
      cartLoading,
      productGallery,
      productGetters,
      cartGetters,
      addingToCart,
      productRecommendation,
      options,
      optionsNotSelected,
      productOptionSelected,
      selectedOptions,
      asku,
      brandStory,
      bundledProductOptions,
      bundledOptionsSelected,
      variantId,
    };
  },
  head: {},
});
</script>
