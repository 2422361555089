import { computed, ComputedRef } from '@nuxtjs/composition-api';
import { productGetters } from '@unified-commerce/gpc-vue-storefront-shopify';
import { isValid, parseISO } from 'date-fns';

import { ProductVariant, ProductViewModel } from '~/components/@types/ProductViewModel';
import { useBrandLink } from '~/composables';
import { CATEGORY_ROUTE_PREFIX } from '~/constants';

const BRAND = 'AMX';

const parsePromoEndDate = (endDate: string, productTitle: string): Date | undefined => {
  const parsedEndDate = parseISO(endDate);
  if (isValid(parsedEndDate)) {
    return parsedEndDate;
  }
  console.warn(`Product ${productTitle} promotion end date ${endDate} is not a valid date format.`);
};

const getGalleryWithPlaceholder = (product: any) => {
  if (product && product.images.length === 0) {
    product.images.push({
      originalSrc:
        'https://cdn.shopify.com/s/files/1/0407/1902/4288/files/placeholder_600x600.jpg?v=1625742127',
    });
  }

  return productGetters.getGallery(product).map((media) => ({
    mobile: { url: media.small },
    desktop: { url: media.normal },
    big: { url: media.big },
    alt: product?.name || '',
    video: media.video,
  }));
};

export const getViewModelProperties = (product: ComputedRef<any>): ProductViewModel => {
  const title = computed(() => productGetters.getName(product.value));
  const variantBySelectedOptions: ComputedRef<ProductVariant> = computed(
    () => productGetters.getVariantBySelectedOptions(product.value) ?? {},
  );
  const hasVariantSelected = computed(() => productGetters.hasVariantSelected(product.value));
  const variantImageIndex = computed(() =>
    productGetters.getVariantImageIndex(
      product.value,
      hasVariantSelected.value ? variantBySelectedOptions.value.image.originalSrc : '',
    ),
  );
  const promotionEndDate = computed(() =>
    parsePromoEndDate(productGetters.getPromotionEndDate(product.value), title.value),
  );

  return {
    variantBySelectedOptions,
    hasVariantSelected,
    variantImageIndex,
    promotionEndDate,
    title,
    id: computed(() => productGetters.getId(product.value)),
    description: computed(() => productGetters.getDescription(product.value)),
    descriptionHtml: computed(() => productGetters.getDescription(product.value, true)),
    partNumber: computed(() =>
      productGetters.getProductVariantMetafield(product.value, 'partNumber'),
    ),
    price: computed(() => productGetters.getPrice(product.value)),
    compareToPrice: computed(() => productGetters.getCompareToPrice(product.value) ?? null),
    sku: computed(() => productGetters.getSKU(product.value)),
    asku: computed(() => productGetters.getProductVariantMetafield(product.value, 'asku')),
    stock: computed(() => productGetters.getStock(product.value)),
    vendor: computed(() => productGetters.getVendorName(product.value)),
    breadcrumbs: computed(() =>
      productGetters.getProductCategoryBreadcrumbs(product.value, BRAND, {
        text: 'All Categories',
        link: `/${CATEGORY_ROUTE_PREFIX}`,
      }),
    ),
    productSpecifications: computed(() => ({
      contentType: 'attribute-list',
      title: 'Specifications',
      content: productGetters.getProductSpecification(product.value) ?? [],
    })),
    detailsAndCare: computed(() => ({
      contentType: 'html',
      title: 'Details & Care',
      content: productGetters.getProductVariantMetafield(product.value, 'detail_care'),
    })),
    weight: computed(() => productGetters.getWeight(product.value) ?? 0),
    brandLink: computed(() => useBrandLink(product.value)),
    productGallery: computed(() => getGalleryWithPlaceholder(product.value)),
    handlingTime: computed(() =>
      productGetters.getProductVariantMetafield(product.value, 'handlingTime'),
    ),
    hasOptions: computed(() => productGetters.hasOptions(product.value)),
    tags: computed(() => productGetters.getTags(product.value)),
    isDeal: computed(() => productGetters.isDeal(product.value)),
    dealExpiryDate: computed(() => productGetters.getDealExpiryDate(product.value)),
  };
};
