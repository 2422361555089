import trim from 'lodash/trim';

import { ProductViewModel } from '~/components/@types/ProductViewModel';

const getStructuredData = (url: string, product: ProductViewModel): any => {
  const structuredData: any = {
    '@context': 'https://schema.org/',
    '@type': 'Product',
    '@id': url,
    name: product.title.value,
    image: product.productGallery.value.map((image) => image.desktop.url),
    description: product.description.value,
    keywords: (product.tags.value || []).join(', '),
    sku: product.sku.value,
    brand: {
      '@type': 'Brand',
      name: product.vendor.value,
    },
    offers: {
      '@type': 'Offer',
      url,
      priceCurrency: 'AUD',
      price: product.price.value,
      itemCondition: 'https://schema.org/NewCondition',
      availability:
        product.stock.value > 0 ? 'https://schema.org/InStock' : 'https://schema.org/OutOfStock',
    },
  };

  if (product.isDeal.value) {
    structuredData.offers.sale_price = product.price.value;
    structuredData.offers.price = product.compareToPrice.value;
    structuredData.offers.priceValidUntil = product.dealExpiryDate.value;
  }

  return structuredData;
};

const productMeta = (baseUrl: string, path: string, product: ProductViewModel) => {
  const url = `https://${baseUrl}${trim(path)}`;

  return {
    title: product.title.value,
    meta: [
      {
        hid: 'description',
        name: 'description',
        content: product.description.value,
      },
      {
        hid: 'og:url',
        property: 'og:url',
        content: url,
      },
      {
        hid: 'og:title',
        property: 'og:title',
        content: product.title.value,
      },
      {
        hid: 'og:type',
        property: 'og:type',
        content: 'product',
      },
      {
        hid: 'og:description',
        property: 'og:description',
        content: product.description.value,
      },
      {
        hid: 'og:image',
        property: 'og:image',
        content: product.productGallery.value[0].desktop.url,
      },
      {
        hid: 'og:image:secure_url',
        property: 'og:image:secure_url',
        content: product.productGallery.value[0].desktop.url,
      },
      {
        hid: 'og:price:amount',
        property: 'og:price:amount',
        content: product.price.value,
      },
      {
        hid: 'og:price:currency',
        property: 'og:price:currency',
        content: 'AUD',
      },
      {
        hid: 'twitter:title',
        name: 'twitter:title',
        content: product.title.value,
      },
      {
        hid: 'twitter:description',
        name: 'twitter:description',
        content: product.description.value,
      },
      {
        hid: 'twitter:image',
        name: 'twitter:image',
        content: product.productGallery.value[0].desktop.url
          ? product.productGallery.value[0].desktop.url
          : '~/static/icons/amx-app-icon-512x512.png',
      },
      {
        hid: 'twitter:card',
        name: 'twitter:card',
        content: product.productGallery.value[0].desktop.url ? 'summary_large_image' : 'summary',
      },
    ],
    script: [
      {
        type: 'application/ld+json',
        json: getStructuredData(url, product),
      },
    ],
  };
};

export default productMeta;
