import { onMounted, onUnmounted, ref } from '@nuxtjs/composition-api';

const useIsTablet = () => {
  const isTablet = ref(false);

  function resizeHandler() {
    isTablet.value = window.matchMedia('(max-width: 1023px)').matches;
  }

  onMounted(() => {
    window.addEventListener('resize', resizeHandler);
    resizeHandler();
  });
  onUnmounted(() => window.removeEventListener('resize', resizeHandler));

  return { isTablet };
};

export default useIsTablet;
