import { computed, ComputedRef, onMounted, ref } from '@nuxtjs/composition-api';
import {
  autocompleteGetters,
  placeDetailsGetters,
  useAutocomplete,
  usePlaceDetails,
} from '@unified-commerce/gpc-vue-storefront-google-places';
import { useQuote } from '@unified-commerce/gpc-vue-storefront-shippit';

import { EstimateMetadata } from '~/composables/useDeliveryEstimate/types';

import { deliveryEstimateFromQuoteResult, getQuoteParams } from './helpers';

const useDeliveryEstimate = (cacheKey: string) => {
  const deliveryEstimateError = ref<string>();
  const {
    query: autocompleteQuery,
    loading: autocompleteLoading,
    error: autocompleteError,
    result: autocompleteRawResult,
  } = useAutocomplete(`useDeliveryEstimate-${cacheKey}`);

  const { getPlaceDetails, result: placeDetailsResult } = usePlaceDetails(
    `useDeliveryEstimate-${cacheKey}`,
  );

  const {
    getQuote,
    clearQuote,
    loading: quoteLoading,
    error: quoteError,
    result: quoteResult,
  } = useQuote(`useDeliveryEstimate-${cacheKey}`);

  const autocompleteResult = computed(() => {
    const { getResults } = autocompleteGetters;
    return autocompleteRawResult?.value ? getResults(autocompleteRawResult.value) : [];
  });

  const deliveryEstimateResult = computed(() => deliveryEstimateFromQuoteResult(quoteResult.value));

  const getEstimate = async (
    placeId: string,
    weight: number,
  ): Promise<EstimateMetadata | undefined> => {
    await getPlaceDetails(placeId);
    if (!placeDetailsResult?.value?.result) {
      deliveryEstimateError.value =
        'Could not estimate to your select suburb. Please try a different location';
      return;
    }
    const location = placeDetailsGetters.getLocation(placeDetailsResult.value);
    await getQuote(getQuoteParams(location, weight));
    if (quoteError.value) {
      deliveryEstimateError.value = 'Unable to estimate a quote. Please try again later.';
      return;
    }
    return {
      locationName: placeDetailsResult.value.result.formatted_address,
      placeId,
    };
  };

  onMounted(() => {
    clearQuote();
    deliveryEstimateError.value = '';
  });

  return {
    autocompleteQuery,
    autocompleteLoading,
    autocompleteError,
    autocompleteResult,
    getEstimate,
    quoteLoading,
    deliveryEstimateResult,
    deliveryEstimateError,
    clearQuote,
  };
};

export default useDeliveryEstimate;
