const useLocalStorage = () => {
  const load = <T>(key: string): T | null => {
    if (!window?.localStorage) return null;
    const item = window.localStorage.getItem(key);
    try {
      return item ? (JSON.parse(item) as T) : null;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const save = <T>(key: string, value: T): void => {
    if (!window?.localStorage) return;
    localStorage.setItem(key, JSON.stringify(value));
  };

  const remove = (key: string): void => {
    if (!window?.localStorage) return;
    window.localStorage.removeItem(key);
  };

  return {
    load,
    save,
    remove,
  };
};

export default useLocalStorage;
