import { UseQuoteResult } from '@unified-commerce/gpc-vue-storefront-shippit';

import { DeliveryEstimateResult, ILocation } from './types';

const getLocation = (quoteResult: UseQuoteResult) =>
  quoteResult ? `${quoteResult.suburb.toUpperCase()} ${quoteResult.postcode}` : '';

const getMessage = (quoteResult: UseQuoteResult) => {
  if (!quoteResult) return '';
  if (quoteResult.estimatedTransitTime.includes('Same day')) {
    return 'Estimated to arrive today';
  } else if (quoteResult.estimatedTransitTime.includes('Unable to')) {
    return 'Unable to estimate delivery time';
  } else {
    return `Estimated to arrive in ${quoteResult.estimatedTransitTime}`;
  }
};

export const deliveryEstimateFromQuoteResult = (
  quoteResult: UseQuoteResult,
): DeliveryEstimateResult | null =>
  quoteResult ? { message: getMessage(quoteResult), location: getLocation(quoteResult) } : null;

export const getQuoteParams = ({ postcode, state, suburb }: ILocation, weight: number) => ({
  postcode: postcode,
  state: state,
  suburb: suburb,
  products: [{ qty: 1, weight: weight ?? 0 }],
});
