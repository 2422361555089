import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14b60be9 = () => interopDefault(import('../pages/Account.vue' /* webpackChunkName: "pages/Account" */))
const _7ab50c8b = () => interopDefault(import('../pages/Account/AccountDetails.vue' /* webpackChunkName: "pages/Account/AccountDetails" */))
const _5224695c = () => interopDefault(import('../pages/Account/Addresses.vue' /* webpackChunkName: "pages/Account/Addresses" */))
const _2c2d8ffd = () => interopDefault(import('../pages/Account/LogoutModal.vue' /* webpackChunkName: "pages/Account/LogoutModal" */))
const _076bddb2 = () => interopDefault(import('../pages/Account/ManageMarketingEmails.vue' /* webpackChunkName: "pages/Account/ManageMarketingEmails" */))
const _06fa92a0 = () => interopDefault(import('../pages/Account/MyBikes.vue' /* webpackChunkName: "pages/Account/MyBikes" */))
const _04e5ccb6 = () => interopDefault(import('../pages/Account/MyOrdersReturnsApp.vue' /* webpackChunkName: "pages/Account/MyOrdersReturnsApp" */))
const _3e9b2cc8 = () => interopDefault(import('../pages/Account/OrderHistory.vue' /* webpackChunkName: "pages/Account/OrderHistory" */))
const _91718e26 = () => interopDefault(import('../pages/Account/TrackMyOrder.vue' /* webpackChunkName: "pages/Account/TrackMyOrder" */))
const _4d6174f6 = () => interopDefault(import('../pages/ActivateAccount.vue' /* webpackChunkName: "pages/ActivateAccount" */))
const _118a3419 = () => interopDefault(import('../pages/Blog/index.vue' /* webpackChunkName: "pages/Blog/index" */))
const _312476c3 = () => interopDefault(import('../pages/Brands/index.vue' /* webpackChunkName: "pages/Brands/index" */))
const _5d05c772 = () => interopDefault(import('../pages/Category.vue' /* webpackChunkName: "pages/Category" */))
const _5fa77075 = () => interopDefault(import('../pages/Category/index.vue' /* webpackChunkName: "pages/Category/index" */))
const _5df0cf2d = () => interopDefault(import('../pages/Category/_slug.vue' /* webpackChunkName: "pages/Category/_slug" */))
const _ca66188c = () => interopDefault(import('../pages/Checkout.vue' /* webpackChunkName: "" */))
const _68722306 = () => interopDefault(import('../pages/Checkout/Billing.vue' /* webpackChunkName: "" */))
const _07217511 = () => interopDefault(import('../pages/Checkout/Payment.vue' /* webpackChunkName: "" */))
const _34e7179a = () => interopDefault(import('../pages/Checkout/Shipping.vue' /* webpackChunkName: "" */))
const _61cb804c = () => interopDefault(import('../pages/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _73c1b452 = () => interopDefault(import('../pages/ForgotPassword.vue' /* webpackChunkName: "pages/ForgotPassword" */))
const _7671f468 = () => interopDefault(import('../pages/Healthcheck.vue' /* webpackChunkName: "pages/Healthcheck" */))
const _45de165a = () => interopDefault(import('../pages/Home.vue' /* webpackChunkName: "pages/Home" */))
const _3a1ae7b6 = () => interopDefault(import('../pages/Login.vue' /* webpackChunkName: "pages/Login" */))
const _efa5c4aa = () => interopDefault(import('../pages/Product.vue' /* webpackChunkName: "pages/Product" */))
const _09577d46 = () => interopDefault(import('../pages/ResetPassword.vue' /* webpackChunkName: "" */))
const _885ddbc8 = () => interopDefault(import('../pages/Search.vue' /* webpackChunkName: "pages/Search" */))
const _708e34e8 = () => interopDefault(import('../pages/Signup.vue' /* webpackChunkName: "pages/Signup" */))
const _64259e46 = () => interopDefault(import('../pages/Zip.vue' /* webpackChunkName: "pages/Zip" */))
const _0fd392d1 = () => interopDefault(import('../pages/Blog/_slug.vue' /* webpackChunkName: "" */))
const _19d74deb = () => interopDefault(import('../pages/Brands/_id.vue' /* webpackChunkName: "" */))
const _4798e78a = () => interopDefault(import('../pages/Pages/_slug.vue' /* webpackChunkName: "pages/Pages/_slug" */))
const _41c0eb68 = () => interopDefault(import('../pages/Stores/_id/_slug.vue' /* webpackChunkName: "pages/Stores/_id/_slug" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _14b60be9,
    name: "Account___en",
    children: [{
      path: "account-details",
      component: _7ab50c8b,
      name: "Account-AccountDetails___en"
    }, {
      path: "addresses",
      component: _5224695c,
      name: "Account-Addresses___en"
    }, {
      path: "logout-modal",
      component: _2c2d8ffd,
      name: "Account-LogoutModal___en"
    }, {
      path: "manage-marketing-emails",
      component: _076bddb2,
      name: "Account-ManageMarketingEmails___en"
    }, {
      path: "my-bikes",
      component: _06fa92a0,
      name: "Account-MyBikes___en"
    }, {
      path: "my-orders-returns-app",
      component: _04e5ccb6,
      name: "Account-MyOrdersReturnsApp___en"
    }, {
      path: "order-history",
      component: _3e9b2cc8,
      name: "Account-OrderHistory___en"
    }, {
      path: "track-my-order",
      component: _91718e26,
      name: "Account-TrackMyOrder___en"
    }]
  }, {
    path: "/activate-account",
    component: _4d6174f6,
    name: "ActivateAccount___en"
  }, {
    path: "/blog",
    component: _118a3419,
    name: "Blog___en"
  }, {
    path: "/brands",
    component: _312476c3,
    name: "Brands___en"
  }, {
    path: "/category",
    component: _5d05c772,
    children: [{
      path: "",
      component: _5fa77075,
      name: "Category___en"
    }, {
      path: ":slug",
      component: _5df0cf2d,
      name: "Category-slug___en"
    }]
  }, {
    path: "/checkout",
    component: _ca66188c,
    name: "checkout___en",
    children: [{
      path: "billing",
      component: _68722306,
      name: "billing___en"
    }, {
      path: "payment",
      component: _07217511,
      name: "payment___en"
    }, {
      path: "shipping",
      component: _34e7179a,
      name: "shipping___en"
    }, {
      path: "thank-you",
      component: _61cb804c,
      name: "thank-you___en"
    }]
  }, {
    path: "/checkout",
    component: _ca66188c,
    name: "Checkout___en",
    children: [{
      path: "billing",
      component: _68722306,
      name: "Checkout-Billing___en"
    }, {
      path: "payment",
      component: _07217511,
      name: "Checkout-Payment___en"
    }, {
      path: "shipping",
      component: _34e7179a,
      name: "Checkout-Shipping___en"
    }, {
      path: "thank-you",
      component: _61cb804c,
      name: "Checkout-ThankYou___en"
    }]
  }, {
    path: "/content-hub",
    component: _118a3419,
    name: "content-hub___en"
  }, {
    path: "/forgot-password",
    component: _73c1b452,
    name: "ForgotPassword___en"
  }, {
    path: "/healthcheck",
    component: _7671f468,
    name: "Healthcheck___en"
  }, {
    path: "/home",
    component: _45de165a,
    name: "Home___en"
  }, {
    path: "/login",
    component: _3a1ae7b6,
    name: "Login___en"
  }, {
    path: "/motorcycle-gear",
    component: _5fa77075,
    name: "categories___en"
  }, {
    path: "/product",
    component: _efa5c4aa,
    name: "Product___en"
  }, {
    path: "/reset-password",
    component: _09577d46,
    name: "reset-password___en"
  }, {
    path: "/reset-password",
    component: _09577d46,
    name: "ResetPassword___en"
  }, {
    path: "/search",
    component: _885ddbc8,
    name: "Search___en"
  }, {
    path: "/signup",
    component: _708e34e8,
    name: "Signup___en"
  }, {
    path: "/zip",
    component: _64259e46,
    name: "Zip___en"
  }, {
    path: "/content-hub/articles/:slug",
    component: _0fd392d1,
    name: "article___en"
  }, {
    path: "/content-hub/videos/:slug",
    component: _0fd392d1,
    name: "video___en"
  }, {
    path: "/content/brands/:slug",
    component: _19d74deb,
    name: "brands-pages___en"
  }, {
    path: "/content/categories/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _5df0cf2d,
    name: "storyblok-categories-pages___en"
  }, {
    path: "/blog/:slug",
    component: _0fd392d1,
    name: "Blog-slug___en"
  }, {
    path: "/brands/:id",
    component: _19d74deb,
    name: "Brands-id___en"
  }, {
    path: "/pages/:slug?",
    component: _4798e78a,
    name: "Pages-slug___en"
  }, {
    path: "/products/:slug",
    component: _efa5c4aa,
    name: "product___en"
  }, {
    path: "/promotions/:slug",
    component: _5df0cf2d,
    name: "promotion___en"
  }, {
    path: "/activate-account/:customerId/:activationToken",
    component: _4d6174f6,
    name: "activate-customer-account___en"
  }, {
    path: "/content-hub/:filterLabel/:filter+",
    component: _118a3419,
    name: "content-hub-filtered___en"
  }, {
    path: "/reset-password/:customerId/:resetToken",
    component: _09577d46,
    name: "reset-customer-password___en"
  }, {
    path: "/stores/:id?/:slug?",
    component: _41c0eb68,
    name: "Stores-id-slug___en"
  }, {
    path: "/c/:slug_1/:slug_2?/:slug_3?/:slug_4?/:slug_5?",
    component: _5d05c772,
    name: "category___en"
  }, {
    path: "/motorcycle-gear/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _5df0cf2d,
    name: "categories-pages___en"
  }, {
    path: "/pages/:slug/:slug2?/:slug3?/:slug4?/:slug5?/:slug6?",
    component: _4798e78a,
    name: "pages___en"
  }, {
    path: "/",
    component: _45de165a,
    name: "home___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
