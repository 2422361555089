import { useAsync, useContext, useRoute } from '@nuxtjs/composition-api';
import { getStoryBlokVersion, useContent } from '@unified-commerce/gpc-vue-storefront-storyblok';

const useSizeGuide = () => {
  const route = useRoute();
  const { $config } = useContext();

  const { search, content: story, loading, error } = useContent(`size-guide-${route.value.params}`);

  useAsync(async () => {
    await search({
      url: 'content/size-guides/generic',
      version: getStoryBlokVersion($config.ENV, route.value),
      optimisticStoryLoading: true,
    });
  }, route.value.path);

  return {
    loading,
    error,
    sizeGuide: story,
  };
};

export default useSizeGuide;
